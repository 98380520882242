import { useState, useEffect } from 'react';
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    addDoc,
    getDoc
} from "firebase/firestore";
import { db } from '../firebaseInit';
import { v4 as uuidv4 } from 'uuid';

export const useGoalStore = (accountId) => {
    const [goals, setGoals] = useState([]);
    const [cacheLastInvalidatedAt, setCacheLastInvalidatedAt] = useState(Date.now());
    const [currentGoal, setCurrentGoal] = useState(null);


    useEffect(() => {
        const fetchGoals = async () => {
            try {
                console.log('useGoalStore fetchGoals: ', accountId)
                const q = query(collection(db, "goals"), where("userId", "==", accountId));

                const querySnapshot = await getDocs(q);

                const goalsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                const sortedGoalList = goalsList.sort((a, b) => {
                  return a.targetAmountInCents - b.targetAmountInCents
                })

                console.log('useGoalStore goalsList: ', sortedGoalList)

                setGoals(sortedGoalList);
            } catch (error) {
                console.error("Error fetching expenses: ", error);
            }
        };

        if (accountId) {
            fetchGoals();
        }
    }, [accountId, cacheLastInvalidatedAt]);

    const addGoal = async ({customTitle, targetAmountInCents, deadlineAt, currentAmountSavedInCents}) => {
            await addDoc(collection(db, "goals"), {
                targetAmountInCents,
                createdAt: (new Date()).toISOString(),
                deadlineAt,
                currentAmountSavedInCents,
                customTitle,
                userId: accountId,
                type: "INVEST_GIVEN_AMOUNT",
            });
            setCacheLastInvalidatedAt(Date.now())
    }

    const selectGoal = async (goal) => {
        setCurrentGoal(goal);
    }

    const updateGoal = async ({id, targetAmountInCents, currentAmountSavedInCents, deadlineAt, customTitle }) => {
            const goalRef = doc(db, "goals", id);

            // Check if the document exists in the database
            const docSnap = await getDoc(goalRef);
            if (!docSnap.exists()) {
                throw new Error("Goal with such id already exists");
            }

            await updateDoc(goalRef, {
                targetAmountInCents: targetAmountInCents,
                currentAmountSavedInCents: currentAmountSavedInCents,
                deadlineAt: deadlineAt,
                customTitle: customTitle,
            });
            setCacheLastInvalidatedAt(Date.now())
    }

    const deleteCurrentGoal = async () => {
        if (currentGoal) {
            const goalRef = doc(db, "goals", currentGoal.id);
            await deleteDoc(goalRef);
            setCurrentGoal(null);
            setCacheLastInvalidatedAt(Date.now())
        }
    }

    return { goals, addGoal, currentGoal, selectGoal, updateGoal, deleteCurrentGoal};
};

export default useGoalStore;
